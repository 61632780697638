<template>
  <div class="login">
    <a-row>
      <a-col :xs="0" :sm="0" :md="14" :lg="14" :xl="14" class="left">
        <div class="banner">
          <div class="title">Welcome To <strong>{{title}}</strong></div>
          <div class="description">{{slogan}}</div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="right">
        <div class="login-section">
          <div class="title">Sign In</div>
          <div class="description">Login using your email & Password</div>
          <div class="login-form">
            <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical" @finish="login" @finishFailed="handleFinishFailed">
              <a-form-item label="Email" class="username" required has-feedback name="email">
                <a-input v-model:value="formState.email" type="text" size="large" placeholder="Enter your email or username" allow-clear autocomplete="off" />
              </a-form-item>
              <a-form-item label="Password" class="password" required has-feedback name="password">
                <a-input-password v-model:value="formState.password" size="large" placeholder="Enter your password" allow-clear autocomplete="off" />
              </a-form-item>
              <div class="user-action">
                <div class="remember-me">
                  <a-form-item name="remember">
                    <a-checkbox v-model:checked="formState.remember">Remember me</a-checkbox>
                  </a-form-item>
                </div>
                <div class="forgot-password" @click="showForgotPopup">
                  <span>Forgot Password?</span>
                </div>
              </div>
              <a-form-item>
                <a-button type="primary" size="large" block class="login-btn" html-type="submit" :loading="loading">
                  Sign In
                </a-button>
              </a-form-item>
            </a-form>
            <div class="register">Don't have account? <span class="link" @click="signup">Sign Up</span></div>
            <!--<div class="social-login">
              <div class="google">
                <img alt="Google Signup" src="../assets/images/google.png" />
              </div>
              <div class="facebook">
                <img alt="Google Signup" src="../assets/images/facebook.png" />
              </div>
            </div>-->
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal title="Forgot Password?" v-model:visible="visible" :footer="null">
      <p>Enter your registered email address. We will send new password to your registered email address.</p>
      <a-form ref="formRef" :model="forgotFormState" :rules="forgotRules" layout="vertical" @finish="sendPassword" @finishFailed="handleFinishFailed">
        <a-form-item label="Email" class="email" required has-feedback name="email">
          <a-input v-model:value="forgotFormState.email" type="text" size="large" placeholder="Enter your email or username" allow-clear autocomplete="off" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="request-button" html-type="submit" :loading="confirmLoading">Request</a-button>
          <a-button class="calcel-button" @click="handleCancel">Cancel</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts">
import auth from '../services/auth'
import validate from '../services/validator'
import router from './../router'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
import { notification } from 'ant-design-vue'

interface FormState {
  email: string;
  password: string;
  remember: boolean;
}

interface ForgotFormState {
  email: string;
}
export default defineComponent({
  props: [],
  setup () {
    const title = ref<string>(process.env.VUE_APP_PROJECT_NAME)
    const slogan = ref<string>(process.env.VUE_APP_SLOGAN)
    const loading = ref<boolean>(false)
    const formState: UnwrapRef<FormState> = reactive({
      email: '',
      password: '',
      remember: false
    })
    const forgotFormState: UnwrapRef<ForgotFormState> = reactive({
      email: ''
    })
    const visible = ref<boolean>(false)
    const confirmLoading = ref<boolean>(false)
    const rules = validate.loginValidation
    const forgotRules = validate.forgotPasswordValidation
    const formRef = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const login = async (values: FormState) => {
      loading.value = true
      try {
        const res = await auth.login(values)
        if (res.data.auth) {
          localStorage.accessToken = res.data.token
          localStorage.profile = JSON.stringify(res.data.profile)
          localStorage.clientLogo = res.data.clientLogo
          localStorage.clientId = res.data.clientId
          if (res.data.profile.profile_type.slug === 'client' && res.data.profile.status === 'unpaid') {
            router.push('/client/subscription')
          } else if (res.data.profile.profile_type.slug === 'candidate') {
            router.push('/candidate/course')
          } else {
            if (res.data.profile.profile_type.slug === 'client' || res.data.profile.profile_type.slug === 'system_users') {
              if (res.data.subscription) {
                localStorage.subscription = JSON.stringify(res.data.subscription[0])
              }
              router.push('/client/dashboard')
            } else {
              router.push('/dashboard')
            }
          }
        } else {
          await notify('Error', res.data, 'error')
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        localStorage.removeItem('accessToken')
        await notify('Error', error.data, 'error')
      }
    }
    const handleFinishFailed = (errors: ValidateErrorEntity<FormState>) => {
      console.log('errors', errors)
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }
    const showForgotPopup = () => {
      visible.value = true
    }
    const sendPassword = async (values: ForgotFormState) => {
      try {
        const res = await auth.forgotPassword({ email: values.email })
        if (typeof res.data === 'boolean') {
          await notify('Success', 'New password has been send to your registered email.', 'success')
          resetForm()
        } else {
          await notify('Error', res.data, 'error')
          return
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
      visible.value = false
      confirmLoading.value = false
    }
    const handleCancel = () => {
      visible.value = false
      confirmLoading.value = false
    }
    const signup = async () => {
      router.push('/signup')
    }
    return {
      formState,
      formRef,
      handleFinishFailed,
      login,
      resetForm,
      loading,
      rules,
      notify,
      showForgotPopup,
      sendPassword,
      forgotFormState,
      visible,
      confirmLoading,
      handleCancel,
      signup,
      forgotRules,
      title,
      slogan
    }
  }
})
</script>
<style lang="scss">
.login {
  background-image: url("../assets/images/login_bg.png");
  background-color: #38B6FF;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  .ant-row,
  .ant-col {
    height: 100%;
  }
  .ant-form-explain {
    text-align: left;
  }
  .left {
    .banner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      .title {
        color: #ffffff;
        font-size: 22px;
      }
      .description {
        color: #ffffff;
        font-size: 16px;
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    .login-section {
      width: 70%;
      .title {
        color: #373737;
        font-size: 28px;
        font-weight: bold;
        text-align: left;
      }
      .description {
        color: #8d8d8d;
        font-size: 14px;
        text-align: left;
        margin: 10px 0 20px;
      }
      .login-form {
        .ant-form-item-label {
          label {
            color: #636363;
          }
        }
        .ant-input,
        .ant-btn {
          border-radius: 20px;
        }
        .password,
        .username {
          margin-bottom: 5px;
        }
        .user-action {
          display: flex;
          padding-bottom: 20px;
          .remember-me,
          .forgot-password {
            width: 50%;
            text-align: left;
          }
          .forgot-password {
            text-align: right;
            span {
              cursor: pointer;
            }
          }
        }
        .login-btn {
          background: #38B6FF;
          border-color: #38B6FF;
        }
        .social-login {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-top: 15px;
          .google,
          .facebook {
            width: 120px;
          }
          img {
            width: 100px;
          }
        }
        .register {
          .link {
            color: #38B6FF;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.ant-modal-root {
  .request-button {
    background: #38B6FF;
    border-color: #38B6FF;
    border-radius: 20px;
    float: right;
    margin-left: 20px;
  }
  .calcel-button {
    border-color: #38B6FF;
    border-radius: 20px;
    float: right;
  }
  .ant-modal-close {
    color: #38B6FF;
  }
}
@media only screen and (max-width: 992px) {
  .login {
    .login-section {
      .title,
      .description {
        color: #ffffff !important;
      }
      .login-form {
        .ant-form-item-label {
          label {
            color: #ffffff !important;
          }
        }
        .register,
        .link {
          color: #ffffff !important;
        }
      }
    }
  }
}
</style>
